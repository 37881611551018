import React from 'react';
import failedIcon from '../../images/failedIcon.png';
import './ErrorPage.css';

function ErrroPage() {
    return (
        <div style={{ height: "92%", width: "100%", backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="error-success-page">
                <img className="error-verify-result" src={failedIcon} alt="successIcon"/>
                <h3 style={{ color: 'red', textAlign: 'center' }}>Сіз ұстанған сілтеменің мерзімі аяқталды.</h3>
            </div>
        </div>
    );
}

export default ErrroPage;