import React from 'react';
import AppStore from '../../images/AppStore.png';
import GooglePlay from '../../images/GooglePlay.png';
import './HomePage.css';

class HomePage extends React.Component {
    render() {
        return (
            <div className="root-container">
                <div className="home-content-body">
                    {/* <video autoPlay muted loop className="home-video-style">
                        <source src={videoPresentation} type="video/mp4" />
                    </video> */}
                    <iframe title="adamjaneomir-presentation" className="home-video-style" src="https://www.youtube.com/embed/Qnor_iKKw2M" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    {/* <img className="home-video-style" src={gifPresentation} alt="videoPresentation"/> */}
                    <div className="home-platform">
                        <a target="_blank" rel="noreferrer" href="https://apps.apple.com/kz/app/%D0%B0%D0%B4%D0%B0%D0%BC-%D0%B6%D3%99%D0%BD%D0%B5-%D3%A9%D0%BC%D1%96%D1%80/id1580061276"><img style={{ height: 60, width: 200, margin: 10 }} src={AppStore} alt="AppStoreIcon"/></a>
                        <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.altynparaq.adamjaneomir"><img style={{ height: 60, width: 200, margin: 10 }} src={GooglePlay} alt="GooglePlayIcon"/></a>
                    </div>
                    <div style={{ backgroundColor: 'rgb(78, 78, 221)', cursor: 'pointer', borderRadius: 10, }}><a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href="https://adamjaneomir.kz"><p style={{ fontSize: 15, padding: 15, margin: 0, color: 'white', textAlign: 'center' }}>adamjaneomir.kz</p></a></div>
                    <div style={{ backgroundColor: 'rgb(226, 229, 226)' }}><p style={{ fontSize: 10, padding: 10, margin: 0, color: 'black' }}>Адам және Өмір © 2021. All rights Reserved. | V5 </p></div>
                </div>
            </div>
        );
    }
}

export default HomePage;