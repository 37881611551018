import successIcon from '../../images/successIcon.png';
import './SuccessPage.css';
function SuccessPage() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const message = params.get("message");
    return (
        <div style={{ height: "92%", width: "100%", backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="success-page-accept">
                <img className="success-verify-result" src={successIcon} alt="successIcon"/>
                <h3 style={{ color: 'green', textAlign: 'center' }}>{message}</h3>
            </div>
        </div>
    );  
}
export default SuccessPage;