import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from './Pages/HomePage/HomePage';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './Pages/TermsOfService/TermsOfService';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import EmailVerificationPage from './Pages/EmailVerificationPage/EmailVerificationPage';
import PasswordResetPage from './Pages/PasswordResetPage/PasswordResetPage';
import Header from './Components/Header/Header';
import SuccessPage from './Pages/SuccessPage/SuccessPage';
import ErrroPage from './Pages/ErrorPage/ErrorPage';
function App() {
  return (
      <Router>
        <div className="App-header">
          <Header />
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/privacypolicy" exact component={PrivacyPolicy} />
            <Route path="/termsofservice" exact component={TermsOfService} />
            <Route path="/userVerification" exact component={EmailVerificationPage} />
            <Route path="/changeUserPassword" exact component={PasswordResetPage} />
            <Route path="/success" exact component={SuccessPage} />
            <Route path="/error" exact component={ErrroPage} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </div>
      </Router>
  );
}

export default App;
