import React from 'react';
import './TermsOfService.css';

class TermsOfService extends React.Component {
    render() {
        return (
            <div className="service-root-container">
                <div className="service-text-contaner">
                    <h1> Приложенияні пайдалану ережелері </ h1>

                    <h2> 1. Шарттар </h2>

                    <p> Адам жанә Омір приложениясі бойынша қол жетімді осы приложенияға кіру арқылы сіз осы приложенияні пайдалану ережелерімен және келісімшарт үшін кез-келген қолданыстағы жергілікті заңдармен жауап беретіндігіңізбен келісесіз. Егер сіз осы шарттардың кез-келгенімен келіспесеңіз, сізге осы сайтқа кіруге тыйым салынады. Осы веб-сайттағы материалдар авторлық құқық және сауда маркасы туралы заңмен қорғалған. </p>

                    <h2> 2. Лицензияны </h2> пайдаланыңыз

                    <p> Altyn Paraq веб-сайтына материалдардың бір данасын тек жеке, коммерциялық емес өтпелі қарау үшін уақытша жүктеуге рұқсат беріледі. Бұл меншік құқығын беру емес, лицензия беру, және сіз бұл лицензия бойынша: </ p> жасай алмайсыз

                    <ul>
                        <li> материалдарды өзгерту немесе көшіру; </li>
                        <li> материалдарды кез-келген коммерциялық мақсатта немесе кез келген көпшілікке көрсету үшін пайдалануға; </li>
                        <li> Altyn Paraq веб-сайтындағы кез-келген бағдарламалық жасақтаманы қайта құруға тырысу; </li>
                        <li> материалдардан кез-келген авторлық құқықты немесе басқа жеке меншік белгілерін алып тастаңыз; немесе </li>
                        <li> материалдарды басқа адамға беру немесе кез-келген басқа сервердегі материалдарды «көрсету». </li>
                    </ul>

                    <p> Бұл Altyn Paraq-қа осы шектеулердің кез келгенін бұзған кезде тоқтатуға мүмкіндік береді. Аяқтағаннан кейін сіздің қарау құқығыңыз да тоқтатылады және сіз кез-келген жүктелген материалдарды баспа түрінде немесе электронды форматта жоюыңыз керек.</p>

                    <h2> 3. Жауапкершіліктен бас тарту </h2>

                    <p> Altyn Paraq веб-сайтындағы барлық материалдар «сол күйінде» берілген. Altyn Paraq ешқандай кепілдік бермейді, ол білдірілуі немесе айтылуы мүмкін, сондықтан барлық басқа кепілдіктерді жоққа шығарады. Сонымен қатар, Altyn Paraq өзінің веб-сайтындағы материалдарды пайдаланудың дұрыстығы мен сенімділігіне қатысты немесе осындай материалдармен немесе осы веб-сайтпен байланысқан кез-келген сайттармен басқаша түрде ешқандай мәлімдеме жасамайды. </p>

                    <h2> 4. Шектеулер </h2>

                    <p> Altyn Paraq немесе оның жеткізушілері Altyn Paraq немесе осы веб-сайттың уәкілетті өкіліне хабарланған болса да, Алтын Paraq веб-сайтындағы материалдарды пайдалану немесе пайдалану мүмкін еместігі салдарынан туындаған кез келген залал үшін жауап бермейді. осындай зақым келу мүмкіндігі туралы жазылған. Кейбір юрисдикциялар болжамды кепілдіктер бойынша шектеулерге немесе кездейсоқ зияндар үшін жауапкершіліктің шектелуіне жол бермейді, бұл шектеулер сізге қатысты болмауы мүмкін. </p>

                    <h2> 5. Түзетулер мен қателіктер </h2>

                    <p> Altyn Paraq веб-сайтында пайда болатын материалдар техникалық, типографиялық немесе фотографиялық қателерді қамтуы мүмкін. Altyn Paraq осы веб-сайттағы материалдардың кез-келгені дәл, толық немесе ағымдағы деп уәде бермейді. Altyn Paraq өзінің веб-сайтындағы материалдарды кез келген уақытта ескертусіз өзгерте алады. Altyn Paraq материалдарды жаңартуға міндеттеме алмайды. </p>

                    <h2> 6. Сілтемелер </h2>

                    <p> Altyn Paraq өзінің веб-сайтына сілтеме жасаған сайттардың барлығын қарастырған жоқ және кез келген осындай сайттың мазмұнына жауап бермейді. Кез-келген сілтеменің болуы сайтты Алтын Paraq-тың мақұлдауын білдірмейді. Кез-келген байланыстырылған веб-сайтты пайдалану пайдаланушының өз тәуекелінде. </p>

                    <h2> 7. Сайтты пайдалану шарттарын өзгерту </h2>

                    <p> Altyn Paraq кез-келген уақытта алдын-ала ескертусіз осы Веб-сайтты пайдалану ережелерін қайта қарауы мүмкін. Осы веб-сайтты пайдалану арқылы сіз осы Пайдалану ережелері мен шарттарының қолданыстағы нұсқасымен келісуге келісесіз. </p>

                    <h2> 8. Сіздің құпиялылығыңыз </h2>

                    <p> Біздің Құпиялылық Саясатымызды оқып шығыңыз. </p>

                    <h2> 9. Басқару заңы </h2>

                    <p> Altyn Paraq веб-сайтына қатысты кез-келген шағым оның kz заңдарымен реттеледі, оның коллизиялық ережелеріне қатысты. </p>
                    <div style={{ backgroundColor: 'rgb(78, 78, 221)', cursor: 'pointer', borderRadius: 10, margin: 70 }}><a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href="https://adamjaneomir.kz"><p style={{ fontSize: 15, padding: 15, margin: 0, color: 'white', textAlign: 'center' }}>adamjaneomir.kz</p></a></div>
                    <div style={{ backgroundColor: 'rgb(226, 229, 226)' }}><p style={{ textAlign: 'center', fontSize: 10, padding: 10, margin: 0, color: 'black' }}>Адам және Өмір © 2021. All rights Reserved.</p></div>
                </div>
            </div>
        );
    }
}

export default TermsOfService;