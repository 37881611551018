import React from 'react';
import applogo from '../../images/logo.png';
import { Link } from 'react-router-dom';
import './Header.css';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor1: "",
            backgroundColor2: "",
            backgroundColor3: "",
            toggle: false,
            count: 0,
            menuText: "|||"
        }
    }
    componentDidMount() {
        const directory = window.location.pathname;
        if (directory === "/") {
            this.setState({
                backgroundColor1: "rgb(72, 72, 219)",
                backgroundColor2: "",
                backgroundColor3: "",
            });
        } else if (directory === "/privacypolicy") {
            this.setState({
                backgroundColor1: "",
                backgroundColor2: "rgb(72, 72, 219)",
                backgroundColor3: "",
            });
        } else if (directory === "/termsofservice") {
            this.setState({
                backgroundColor1: "",
                backgroundColor2: "",
                backgroundColor3: "rgb(72, 72, 219)",
            });
        }
    }
    onClickNavBar(event) {
        if (event === 1) {
            this.setState({
                backgroundColor1: "rgb(72, 72, 219)",
                backgroundColor2: "",
                backgroundColor3: "",
            });
        } else if (event === 2) {
            this.setState({
                backgroundColor1: "",
                backgroundColor2: "rgb(72, 72, 219)",
                backgroundColor3: "",
            });
        } else if (event === 3) {
            this.setState({
                backgroundColor1: "",
                backgroundColor2: "",
                backgroundColor3: "rgb(72, 72, 219)",
            }); 
        }
    }
    onClickMenu() {
        this.setState({
            toggle: !this.state.toggle,
            menuText: this.state.toggle ? "|||" : "X",
        });
        if (this.state.toggle === false) {
            const navLinks = document.querySelectorAll(".header-ul li");
            navLinks.forEach((link, index) => {
                link.style.animation = `navLinkFade 0.8s ease forwards ${index / 7}s`;
            });
        } else {
            const navLinks = document.querySelectorAll(".nav-active li");
            navLinks.forEach((link) => {
                link.style.animation = ``;
            });
        }
    }
    render() {
        return(
            <header className="header-root-header">
                <img height="50px" style={{ cursor: "pointer" }} src={applogo} alt="logoImage" />                 
                <nav className="header-nav-style">
                    <ul className={this.state.toggle === true ? "nav-active" : "header-ul"}>
                        <li style={{ backgroundColor: this.state.backgroundColor1 }} className="header-li-1">
                            <Link className="Link" onClick={this.onClickNavBar.bind(this, 1)} to="/">Басты_бет</Link>
                        </li>
                        <li style={{ backgroundColor: this.state.backgroundColor2 }} className="header-li-2">
                            <Link className="Link" onClick={this.onClickNavBar.bind(this, 2)} to="/privacypolicy">Құпиялылық_саясаты</Link>
                        </li>
                        <li style={{ backgroundColor: this.state.backgroundColor3 }} className="header-li-3">
                            <Link className="Link" onClick={this.onClickNavBar.bind(this, 3)} to="/termsofservice">Қызмет_көрсету_шарттары</Link>
                        </li>
                    </ul>
                </nav>
                <div className="header-menu-style" onClick={this.onClickMenu.bind(this)}>{this.state.menuText}</div>
            </header>
        );
    }
}
export default Header;