import React from 'react';
import pagenotfoundimage from '../../images/404.jpg'
function PageNotFound() {
    return(
        <div style={{ height: "92%", width: "100%", backgroundColor: 'rgb(4, 4, 36)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img height="300px" src={pagenotfoundimage} alt="404_PageNotFound"/>
        </div>
    );
}

export default PageNotFound;