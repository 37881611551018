import React from 'react';
import { Squares } from 'react-activity';
import 'react-activity/lib/Squares/Squares.css';
import './PasswordResetPage.css';
import logo from '../../images/logo.png';
import { ApiKey, checkMailCodeURL, resetPassowrdURL } from '../../ConstantData/CommonCodes';
class PasswordResetPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            repeatPassword: "",
            message: "",
            closeForm: true,
            isSuccess: null
        }
    }

    componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const code = params.get("code");
        if (search !== "" && code !== null && code !== "" && code.length === 40) {
            // red
            const url = checkMailCodeURL + search;
            const request = {
                method: "GET",
                headers: {
                    "GC-ApiKey": ApiKey,
                    "Content-Type": "application/json",
                }
            }
            fetch(url, request).then(async response => {
                if (response.status === 200) {
                   this.setState({
                       closeForm: false,
                   }); 
                } else {
                    this.setState({
                        closeForm: true,
                        isSuccess: false,
                        message: "Сіз ұстанған сілтеменің мерзімі аяқталды.",
                    });
                }
            });
        } else {
            this.setState({
                closeForm: true,
                isSuccess: false,
                message: "Қате сұрау болды."
            });
        }
    }

    enterPassword = (event) => {
        this.setState({
            password: event.target.value,
        });
    }
    repeatPassword = (event) => {
        this.setState({
            repeatPassword: event.target.value,
        });
    }
    onChangePassword = (event) => {
        event.preventDefault();
        if(this.state.password !== "" && this.state.repeatPassword !== "") {
            var expression = new RegExp("^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$");
            var passwordValid = expression.test(this.state.password);
            if (passwordValid === true && this.state.password === this.state.repeatPassword) {
                this.setState({
                    closeForm: true,
                    isSuccess: null,
                });
                const search = this.props.location.search;
                const params = new URLSearchParams(search);
                const code = params.get("code");
                const url = resetPassowrdURL;
                const request = {
                    method: "POST",
                    headers: {
                        "GC-ApiKey": ApiKey,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify ({
                        "pass": this.state.password.toString(),
                        "code": code.toString()  
                    })
                }
                fetch(url, request).then(async response => {
                    if (response.status === 200) {
                        // if it is successull then return success message
                        window.location = "/success?message=Сіздің құпия сөз сәтті өзгертілді.";
                    } else {
                        // if there is an error then return error message
                        window.location = "/error";
                    }
                }).catch(error => {
                    this.setState({
                        closeForm: true,
                        isSuccess: false,
                        message: error.toString(),
                    });
                    console.error(error);
                });
            }else {
                if (passwordValid === false) {
                    alert("Құпия сөз, кем дегенде 8 таңба, 1 үлкен және 1 кішкентай әріп, 1 сан болүы керек.");
                } else if (this.state.password !== this.state.repeatPassword) {
                    alert("Құпия сөздер сәйкес келмейді.");
                }
            }
        } else {
            alert("You need to fill all TextFields below.");
        }
        
    }
    renderContent() {
        if (this.state.closeForm === true) {
            if (this.state.isSuccess === false) {
                window.location = "/error";
            }else {
                return <Squares color="rgb(189, 36, 209)" />
            }
        } else {
            return (
                <div className="rootContainer">
                    <img style={{ width: 150, }} src={logo} alt="adam jane omir logo"/>
                    <h5 style={{ color: "black", }}>Құпия сөзді өзгәрт!</h5>
                    <form className="formStyle" onSubmit={this.onChangePassword}>
                        <input required className="inputStyle" placeholder="Жаңа Құпия сөз" type="password" value={this.state.password} onChange={this.enterPassword} />
                        <input required className="inputStyle" placeholder="Құпия сөзді қайтала" type="password" value={this.state.repeatPassword} onChange={this.repeatPassword} />
                        <button className="buttonStyle" type="submit">Өзгәрт</button>
                    </form>
                </div>
            );
        }
    }
    render() {
        return (
            <div style={{ height: "92%", width: "100%", backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {this.renderContent()}
            </div>
        );
    }
}

export default PasswordResetPage;