import React from 'react';
import './PrivacyPolicy.css';

class PrivacyPolicy extends React.Component {
    render() {
        return(
            <div className="privacy-root-container">
                <div className="privacy-text-contaner">
                    <h1> Altyn Paraq үшін құпиялылық саясаты </h1>

                    <p> Адам және өмір, https://www.adamjaneomir.app сайтында қол жетімді, біздің басты басымдықтардың бірі - келушілердің жеке өмірі. Бұл Құпиялылық саясаты құжатында Адам және өмір жинайтын және жазатын ақпарат түрлері және оны қалай қолданамыз. </p>

                    <p> Егер сізде қосымша сұрақтар туындаса немесе біздің Құпиялылық саясатымыз туралы көбірек ақпарат қажет болса, бізбен хабарласыңыз. </p>

                    <p> Осы Құпиялылық саясаты тек біздің онлайн-әрекеттерімізге қолданылады және біздің веб-сайтқа кірушілер үшін олар Адам және өмірде бөліскен және / немесе жинайтын ақпаратқа қатысты қолданылады. Бұл саясат оффлайн режимінде немесе осы веб-сайттан басқа арналар арқылы жиналған ақпаратқа қолданылмайды. Біздің құпиялылық саясатымыз <a href="https://www.privacypolicyonline.com/privacy-policy-generator/"> ақысыз құпиялылық саясатының генераторы </a> көмегімен жасалған. </p>

                    <h2> Келісім </ h2>

                    <p> Біздің веб-сайтымызды пайдалану арқылы сіз Құпиялылық саясатымен келісесіз және оның шарттарымен келісесіз. </p>

                    <h2> Біз жинайтын ақпарат </h2>

                    <p> Сізден сұралатын жеке ақпарат және оны берудің себептері сізге жеке ақпаратыңызды беруіңізді сұраған кезде сізге түсінікті болады. </p>
                    <p> Егер сіз бізге тікелей хабарлассаңыз, біз сіз туралы атыңыз, электрондық поштаңыздың мекен-жайы, телефон нөміріңіз, хабарламаның мазмұны және / немесе бізге жібере алатын тіркемелер және сіз таңдауға болатын кез-келген басқа ақпарат туралы қосымша ақпарат ала аламыз. . </p>
                    <p> Тіркелгіге тіркелу кезінде біз сіздің байланыс ақпаратыңызды, оның ішінде аты, компанияның аты, мекен-жайы, электрондық пошта мекен-жайы және телефон нөмірін сұрай аламыз. </p>

                    <h2> Сіздің ақпаратыңызды қалай қолданамыз </ h2>

                    <p> Біз жиналатын ақпаратты әртүрлі тәсілдермен қолданамыз, соның ішінде: </p>

                    <ul>
                    <li> Біздің веб-сайтты қамтамасыз етіңіз, жұмыс жасаңыз және күтіңіз </li>
                    <li> Веб-сайтыңызды жақсартыңыз, жекелендіріңіз және кеңейтіңіз </li>
                    <li> Біздің веб-сайтты қалай қолданатыныңызды түсініп, талдаңыз </li>
                    <li> Жаңа өнімдерді, қызметтерді, мүмкіндіктерді және функционалдылықты дамыту </li>
                    <li> Сізге тікелей немесе серіктестеріміздің бірі арқылы, соның ішінде клиенттерге қызмет көрсету үшін, веб-сайтқа қатысты жаңартулар мен басқа ақпараттар беру, маркетинг және жарнамалық мақсаттар үшін сізбен байланысыңыз </li>
                    <li> Электрондық пошта хабарларын жіберу </li>
                    <li> Алаяқтықты табыңыз және алдын алыңыз </li>
                    </ul>

                    <h2> Журнал файлдары </h2>

                    <p> Адам және өмір журнал файлдарын пайдаланудың стандартты процедурасын сақтайды. Бұл файлдар веб-сайттарға кірген кезде кірушілерді тіркейді. Барлық хостингтік компаниялар мұны және хостинг қызметтерінің аналитикасының бір бөлігін жасайды. Журнал файлдары бойынша жиналатын ақпараттарға интернет-хаттама (IP) мекен-жайлары, шолғыш түрі, Интернет-провайдер (ISP), күн мен уақыт мөрі, сілтеме / шығу парақтары және мүмкін басылған саны кіреді. Бұлар жеке анықтауға болатын кез-келген ақпаратпен байланысты емес. Ақпараттың мақсаты - тенденцияларды талдау, сайтты басқару, веб-сайттағы қолданушылардың қозғалысын бақылау және демографиялық ақпарат жинау. </p>




                    <h2> Жарнама серіктестері құпиялылық саясаты </h2>

                    <p> Адам және өмір жарнамалық серіктестерінің әрқайсысы үшін Құпиялылық саясатын табу үшін сіз осы тізімге жүгіне аласыз. </p>

                    <p> Үшінші тараптың жарнама серверлері немесе жарнама желілері cookie файлдары, JavaScript немесе Web Beacons сияқты технологияларды қолданады, олар өздерінің жарнамаларында қолданылады және сілтемелерде қолданушы браузеріне тікелей жіберілетін Адам және өмірде пайда болатын сілтемелерде. Бұл орын алған кезде олар сіздің IP-мекен-жайыңызды автоматты түрде алады. Бұл технологиялар олардың жарнамалық кампанияларының тиімділігін өлшеу және / немесе сіз кірген веб-сайттардан көретін жарнамалық мазмұнды жекелендіру үшін қолданылады. </p>

                    <p> Адам және өмірде үшінші тарап жарнамалаушылары пайдаланатын бұл куки-файлдарға қол жетімділік немесе оларды бақылау мүмкіндігі жоқ екенін ескеріңіз. </p>

                    <h2> Үшінші тараптың құпиялылық саясаты </h2>

                    <p> Адам және өмірдің құпиялылық саясаты басқа жарнама берушілерге немесе веб-сайттарға қолданылмайды. Осылайша, біз сізге егжей-тегжейлі ақпарат алу үшін осы үшінші тараптың жарнама серверлерінің құпиялылық саясатына жүгінуге кеңес береміз. Мұнда олардың тәжірибелері мен белгілі бір нұсқалардан бас тарту туралы нұсқаулар болуы мүмкін. </p>

                    <p> Сіз өзіңіздің жеке браузеріңіз арқылы кукиді өшіруді таңдай аласыз. Белгілі бір веб-браузерлерде cookie файлдарын басқару туралы толығырақ ақпарат алу үшін оны браузерлердің тиісті веб-сайттарында табуға болады. </p>

                    <h2> CCPA құпиялылық құқықтары (менің жеке ақпараттарымды сатпаңыз) </h2>

                    <p> CCPA-ға сәйкес, басқа құқықтармен қатар, Калифорния тұтынушылары: </p> құқылы
                    <p> Тұтынушының жеке деректерін жинайтын кәсіпкердің тұтынушылар туралы жинаған жеке деректерінің санаттары мен нақты бөліктерін ашуын сұрау. </p>
                    <p> Бизнеске тұтынушы туралы жеке жинақтаған жеке деректерді жоюды сұрау. </p>
                    <p> Тұтынушының жеке деректерін сатуды емес, тұтынушының жеке деректерін сатуды сұраңыз. </p>
                    <p> Егер сіз өтініш жасасаңыз, сізге жауап беруге бір ай уақыт бар. Егер сіз осы құқықтардың кез-келгенін пайдаланғыңыз келсе, бізге хабарласыңыз. </p>
                    <div style={{ backgroundColor: 'rgb(78, 78, 221)', cursor: 'pointer', borderRadius: 10, margin: 70 }}><a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href="https://adamjaneomir.kz"><p style={{ fontSize: 15, padding: 15, margin: 0, color: 'white', textAlign: 'center' }}>adamjaneomir.kz</p></a></div>
                    <div style={{ backgroundColor: 'rgb(226, 229, 226)' }}><p style={{ textAlign: 'center', fontSize: 10, padding: 10, margin: 0, color: 'black' }}>Адам және Өмір © 2021. All rights Reserved.</p></div>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;