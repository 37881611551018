import React from 'react';
import { Squares } from 'react-activity';
import 'react-activity/lib/Squares/Squares.css';
import { verifyEmailURL } from '../../ConstantData/CommonCodes';
import './EmailVerificationPage.css';

class EmailVerificationPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: "",
            isSuccess: null,
        }
    }

    componentDidMount() {
        // get url params from current page
        const search = this.props.location.search;
        // then pass it to request url as GET method
        const url = verifyEmailURL + search.toString();
        const request = {
            method: "GET",
            headers: {
                "GC-ApiKey": "G0Ld3NCh41N",
                "Content-Type": "application/json",
            }
        }
        const params = new URLSearchParams(search);
        const code = params.get("code");
        if (code === "" || code === null) {
            this.setState({
                isSuccess: false,
                message: "Сіз сілтемені өзгерттіңіз. Сілтемені өзгертуге болмайды.",
            });
        } else {
            fetch(url, request).then(async response => {
                if (response.status === 200) {
                    // if it is successull then return success message
                    this.setState({
                        isSuccess: true,
                        message: "Рақмет сізге. Сіздің электрондық поштаңыз сәтті расталды.",
                    });
                    window.location = "/success?message=Рақмет сізге. Сіздің электрондық поштаңыз сәтті расталды.";
                    let stateObj = { id: "100" };
                    window.history.replaceState(stateObj, "", window.location.origin + "/userVerification");
                } else {
                    // if there is an error then return error message
                    this.setState({
                        isSuccess: false,
                        message: "Сіз ұстанған сілтеменің мерзімі аяқталды.",
                    });
                    window.location = "/error";
                    let stateObj = { id: "100" };
                    window.history.replaceState(stateObj, "", window.location.origin + "/userVerification/?code=");
                }
            }).catch(error => {
                this.setState({
                    message: error.toString(),
                });
            });
        }
    }
    renderComponents() {
        if (this.state.isSuccess === false) {
            window.location = "/error"
        } else if (this.state.isSuccess === null) {
            return (
                <Squares color="rgb(189, 36, 209)" />
            );
        }
    }

    render() {
        return (
            <div style={{ height: "92%", width: "100%", backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {this.renderComponents()}
            </div>
        );
    }
}

export default EmailVerificationPage;